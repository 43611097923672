<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('dataForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="素材类型" prop="qtso_type">
            <el-select v-model="dataForm.qtso_type" clearable @change="qtsoTypeChange">
              <el-option v-for="item in qtsoTypeList" :key="item.value" :value="item.value" :label="item.label"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材名称" prop="qtso_name">
            <el-input v-model.trim="dataForm.qtso_name" maxlength="20" show-word-limit placeholder="请填写素材名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材规格" prop="qtso_spec">
            <el-input v-model.trim="dataForm.qtso_spec" placeholder="请填写素材规格" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="是否计算损耗" prop="qtso_loss">
            <el-radio v-model="dataForm.qtso_loss" label="1">是</el-radio>
            <el-radio v-model="dataForm.qtso_loss" label="0">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="是否是日价" prop="qtso_daypr">
            <el-radio v-model="dataForm.qtso_daypr" label="1" :disabled="dataForm.qtso_type === '4'">是</el-radio>
            <el-radio v-model="dataForm.qtso_daypr" label="0" :disabled="dataForm.qtso_type === '4'">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材价格" prop="qtso_price">
            <el-input
              maxlength="9"
              v-model="dataForm.qtso_price"
              :disabled="!dataForm.qtso_daypr"
              placeholder="请填写素材价格"
              show-word-limit
              @input="
                dataForm.qtso_price =
                  dataForm.qtso_price
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
              @change="dataForm.qtso_price = helper.calcPrice(dataForm.qtso_price, 4, 10000)"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { QtsoAPI } from '@api/modules/qtso';
import { post } from '@/api/request';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CustAddMain',
  components: {
    addHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        qtso_type: [{ required: true, trigger: 'blur', message: ' ' }],
        qtso_name: [{ required: true, trigger: 'blur', message: ' ' }],
        qtso_price: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        qtso_daypr: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        qtso_loss: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      dataForm: {
        qtso_type: null,
        qtso_name: null, //素材名称
        qtso_spec: null,
        qtso_price: null,
        qtso_daypr: null,
        qtso_loss: '0'
      },
      qtsoTypeList: [
        { value: '1', label: '原材料' },
        { value: '2', label: '香精' },
        { value: '3', label: '夜光粉' },
        { value: '4', label: '颗粒' },
        { value: '5', label: '矿粉' },
        { value: '6', label: '色粉' },
        { value: '7', label: '成品损耗' },
        { value: '8', label: '辅助料材' },
        { value: '9', label: '水电费' },
        { value: '10', label: '运费' },
        { value: '11', label: '纸箱' },
        { value: '12', label: '塑料袋' },
        { value: '13', label: '人工工资' }
      ]
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let dataForm = Object.assign(this.dataForm, staffForm);
      dataForm.personal = this.$cookies.get('userInfo').personal;
      post(QtsoAPI.addQtso, this.dataForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
            this.jump('/qtso_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                })
              )
            });
            this.resetForm('dataForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('dataForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    qtsoTypeChange(val) {
      if (val === '4') {
        this.dataForm.qtso_spec = '填写日产量后显示计算价格';
        this.dataForm.qtso_daypr = '1';
      } else {
        if (this.dataForm.qtso_spec === '填写日产量后显示计算价格') {
          this.dataForm.qtso_spec = '';
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}
</style>
